import axios from "axios";
import md5 from "md5";
import {replaceList} from "../../utils/constants";
import {replaceWords} from "../helpers";

const getLLMResponse = async (sessionId, moduleId, query = '', prompt = '', ragId = '', llm = '', queryPrefix = '', llmResponsePrefix = '', saveContent = true, contextLimit = {type:"count",value:10})=> {
  try {
    
    if (!sessionId) {
      throw new Error('Session ID not found');
    }

    if (!moduleId) {
      throw new Error('Module ID not found');
    }

    const conversationId = sessionId + '-' + md5(moduleId);

    const data = {
      sessionId,
      moduleId,
      conversationId,
      query,
      prompt,
      ragId,
      llm,
      queryPrefix,
      llmResponsePrefix,
      saveContent,
      contextLimit
    }

    const response = await axios.post(`${process.env.REACT_APP_API}/api/llm/`,data);

    // Data replace
    if (response?.data && response?.data.content) {
      response.data.content = replaceWords(response.data.content, replaceList);
    }

    if (response?.data) {
      response.data = {...data, ...response.data};
    }
      
    return response;
  } catch (e) {
    console.log(e);
    console.log(sessionId);
    console.log(moduleId);
    throw new Error('An error occurred while getting LLM Response2');
  }
}

const getEvalReponse = async (sessionId, moduleId)=> {
  try {
    
    if (!sessionId) {
      throw new Error('Session ID not found');
    }

    if (!moduleId) {
      throw new Error('Module ID not found');
    }
    
    const data = {
      sessionId,
      moduleId
    }

    const response = await axios.post(`${process.env.REACT_APP_API}/api/evaluation`,data);

      if (response?.data) {
        response.data = {...data, ...response.data};
      }
      
      return response;
  } catch (e) {
    console.log(e);
    throw new Error('An error occurred while getting Eval Response');
  }
}

const getEvalBySession = async (sessionId)=> {
  try {
    
    if (!sessionId) {
      throw new Error('Session ID not found');
    }

    const response = await axios.get(`${process.env.REACT_APP_API}/api/evaluation/session/` + sessionId);
      
    return response;
  } catch (e) {
    console.log(e);
    throw new Error('An error occurred while getting Eval Response');
  }
}

const getEvalByModule = async (moduleId)=> {
  try {
    
    if (!moduleId) {
      throw new Error('Module ID not found');
    }

    const response = await axios.get(`${process.env.REACT_APP_API}/api/evaluation/module/` + moduleId);
      
    return response;
  } catch (e) {
    console.log(e);
    throw new Error('An error occurred while getting Eval Response');
  }
}

const getEvalById = async (evalId)=> {
  try {
    
    if (!evalId) {
      throw new Error('Eval ID not found');
    }

    const response = await axios.get(`${process.env.REACT_APP_API}/api/evaluation/` + evalId);
      
    return response;
  } catch (e) {
    console.log(e);
    throw new Error('An error occurred while getting Eval Response');
  }
}

const getModule = async (moduleId)=> {
  try {
    
    if (!moduleId) {
      throw new Error('Module ID not found');
    }

    let response = {};

    const scenario = await axios.get(`${process.env.REACT_APP_API}/api/scenario/` + moduleId);
    if (scenario.data?.scenario) {
      response = scenario.data?.scenario;
    }

    if (!response) {
      const assistant = await axios.get(`${process.env.REACT_APP_API}/api/assistant/` + moduleId);
      if (assistant.data?.assistant) {
        response = assistant.data?.assistant;
      }
    }

    return response;
  } catch (e) {
    console.log(e);
    throw new Error('An error occurred while getting Module Data');
  }
}

const getAssistantModules = async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API}/api/assistant`);

    return response?.data?.assistants;
  } catch (e) {
    throw new Error('An error occurred while getting Assistant Modules');
  }
};

const getScenarioModules = async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API}/api/scenario`);

    return response?.data?.scenarios;
  } catch (e) {
    throw new Error('An error occurred while getting Scenario Modules');
  }
};

const getSummaryModules = async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API}/api/summary/module`);

    return response?.data?.summaryModules;
  } catch (e) {
    throw new Error('An error occurred while getting Summary Modules');
  }
};

const getSummaryModulesByTenant = async (tenantId) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API}/api/summary/module/tenant/${tenantId}`);

    return response?.data?.summaryModules;
  } catch (e) {
    throw new Error('An error occurred while getting Summary Modules');
  }
};

const getSummaryModule = async (moduleId) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API}/api/summary/module/${moduleId}`);

    return response?.data?.summaryModule;
  } catch (e) {
    throw new Error('An error occurred while getting Summary Module');
  }
};

const getSummariesByModuleId = async (moduleId) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API}/api/summary/module/${moduleId}/list`);

    return response?.data?.summaries;
  } catch (e) {
    throw new Error('An error occurred while getting Summaries');
  }
};

const generateSummary = async (moduleId, rangeStart, rangeEnd) => {
  try {
    const data = {
      moduleId,
      rangeStart,
      rangeEnd,
      saveContent: true
    };

    const response = await axios.post(`${process.env.REACT_APP_API}/api/summary`, data);

    return response?.data;
  } catch (e) {
    throw new Error('An error occurred while generating Summary');
  }
};

const getModulesByDemo = async (demoId) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API}/api/module/demo/${demoId}`);

    return response?.data?.modules;
  } catch (e) {
    throw new Error('An error occurred while getting Modules');
  }
};

const getModulesByTenant = async (tenantId) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API}/api/module/tenant/${tenantId}`);

    return response?.data?.modules;
  } catch (e) {
    throw new Error('An error occurred while getting Modules');
  }
}

export {
  getLLMResponse,
  getEvalReponse,
  getEvalBySession,
  getModule,
  getEvalByModule,
  getEvalById,
  getAssistantModules,
  getScenarioModules,
  getSummaryModules,
  getSummaryModulesByTenant,
  getSummaryModule,
  getSummariesByModuleId,
  generateSummary,
  getModulesByDemo,
  getModulesByTenant
}
