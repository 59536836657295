import axios from "axios";
import { isExpired } from "react-jwt";
import {getLocal, setLocal} from "./dataFetching";

export const getToken = () => getLocal('auth_token');

export const getTenantByCode = async (code) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API}/api/tenant/code/${code}`);
    
    if (response?.data?.tenant) {
      const tenant = {
        ...response.data.tenant,
        assistants: response.data.assistants,
        scenarios: response.data.scenarios
      };

      setLocal('tenant', tenant);
      setLocal('ccode', response.data.code);
      
      setLocal("auth_token", response.data.token);
      setLocal("d_id_key", response.data.apiKey);
      
      setLocal("avatar_url", process.env.REACT_APP_AVATAR_URL);
      setLocal("avatar_audio", process.env.REACT_APP_AVATAR_AUDIO);
      setLocal("kb_audio", process.env.REACT_APP_KB_AUDIO);

      return tenant;
    }
    
    return false;
  } catch (error) {
    return false;
  }
};

export const isTokenExpired = (token) => {
  const decodedToken = isExpired(token);
  if (!decodedToken) return true;

  return false;
};