import React, {useCallback, useEffect, useRef, useState} from 'react';

import '../stylesheets/AudioControl.scss';
import {getAudioTranscript} from "../../utils/fetch/speechFetching";
import {wakeWords, stopRecordingDelay, maxAudioRecording} from "../../utils/constants";
import {getLocal, getSess, setSess} from "../../utils/dataFetching";
import { useErrorBoundary } from "react-error-boundary";
import Modal from './Modal';

let delayIntervalID = null;
let stopRecordingTimeoutID = null;

const AudioControl = ({ onStateChange, audioState, isTalking, analyzer, audioRef, module, voiceActivated }) => {
  const { showBoundary } = useErrorBoundary();

  const [tenant, setTenant] = useState(getLocal('tenant'));

  const [isRecording, setIsRecording] = useState(false);
  const speechRecognitionRef = useRef(null);
  const [isListening, setIsListening] = useState(true);
  const [isEnding, setIsEnding] = useState(false);
  const [isMediaRecorderLoaded, setIsMediaRecorderLoaded] = useState(false);
  const [isAllowed, setIsAllowed] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const mediaRecorderRef = useRef(null);
  const audioStreamRef = useRef(null);

  useEffect(() => {
    async function checkPermissions() {
      const permission = await navigator.permissions.query({ name: 'microphone' });

      setIsAllowed(permission?.state !== 'denied');
      setIsReady(true);
    }

    checkPermissions();
  }, []);

  const stopListening = useCallback(() => {
    if (isAllowed) {
      if (speechRecognitionRef.current) {
        console.log('STOP LISTENING');
        setIsListening(false);
        setSess('speechRecognitionRef', 'stopped');
        speechRecognitionRef.current.stop();
        console.log('STOPPED LISTENING');
        //speechRecognitionRef.current = null;
      }
    }
  }, [speechRecognitionRef, isAllowed]);

  const stopRecording = useCallback (() => {
    console.log('stopRecording');

    if (isAllowed) {
      console.log('stopRecording - is allowed');

      // Clear and null the delay interval so it doesn't keep firing
      clearRecordingDelayInterval();
      clearStopRecordingTimeoutID();

      if (mediaRecorderRef.current && mediaRecorderRef?.current?.state === "recording") {
        console.log('stopRecording - DEEP');

        stopListening();

        setIsRecording(false);
        mediaRecorderRef.current.stop();
        setSess('isRecording', 'false');


        if (audioStreamRef.current && audioStreamRef.current.getAudioTracks) {
          console.log('stopping audioStreamRef.current');
          const tracks = audioStreamRef.current.getAudioTracks();
          tracks[0].stop();
        }
      }
    }
  }, [mediaRecorderRef, audioStreamRef, stopListening, isAllowed]);

  useEffect(() => {
    if (isAllowed) {
      document.addEventListener('visibilitychange', () => {
        if (speechRecognitionRef.current) {
          if (document.hidden) {
            stopListening();
            stopRecording();
          } else {
            setIsListening(true);
            speechRecognitionRef.current.start();
          }
        }
      });
    }
  }, [stopListening, stopRecording, isAllowed]);


  const startRecording = useCallback(() => {
    if (isAllowed) {
      if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
        console.error('Media Devices API or getUserMedia not supported in this browser.');
        return;
      }
  
      try {
        //stopListening();
  
        if (audioStreamRef.current && audioStreamRef.current.getAudioTracks) {
          console.log('starting audioStreamRef.current');
          const tracks = audioStreamRef.current.getAudioTracks();
          tracks[0].start();
        }
  
        console.log('starting mediaRecorderRef.current');
        setIsRecording(true);
        onStateChange('recording');
        setSess('isRecording', 'true');

        // Need to check this more
        // Brian 7-17
        mediaRecorderRef.current.start(1000);


        // stopRecordingTimeoutID = setTimeout(() => {
        //     stopRecording();
        // }, maxAudioRecording);
      } catch (err) {
        showBoundary(err);
      }
    }
  }, [mediaRecorderRef, audioStreamRef, onStateChange, isAllowed, showBoundary, setIsRecording]);

  const onSpeechRecognitionEnd = useCallback((e) => {
    if (isAllowed) {
      if (!document.hidden) {
        console.log("speech recognition - onend",e,e.returnValue);
  
        console.log(isListening,isTalking);
        if (isListening && !isTalking) {
          console.log("restarting speech recognition service");
  
          if (getSess('speechRecognitionRef') === 'started') {
            speechRecognitionRef.current.start();
          }
          //speechRecognitionRef.current.start(); //restart recognition
        }
      }
    }
  }, [speechRecognitionRef, isListening, isTalking, isAllowed]);

  const onSpeechRecognitionResult = useCallback((event) => {
    if (isAllowed) {
      console.log("speech recognition - onresult");
      const last = event.results.length - 1;
      const text = event.results[last][0].transcript.trim().replace(/[^-a-z0-9\s]/ig,'');
  
      //reset the interval to stop recording audio
      if(delayIntervalID !== null) {
        //if it isn't null, fully remove it first
        clearInterval(delayIntervalID);
        delayIntervalID = null;
  
        delayIntervalID = setInterval(() => {
          // stop recording audio
          if(isRecording || getSess('isRecording')) { 
            console.log("delayInterval::stopRecording");
            stopRecording();
          }
        }, stopRecordingDelay);
      } else {
        delayIntervalID = setInterval(() => {
          // stop recording audio
          if(isRecording || getSess('isRecording')) {
            // console.log("delayInterval::stopRecording");
            stopRecording();
          }
        }, stopRecordingDelay);
      }
  
      // if we recognize the wake word
      console.log("Recognized text:",text);
  
      //if (text.toLowerCase().includes(wakeWord)) {
      if (wakeWords.some(str => new RegExp(`^${text}$`, "i").test(str)) && isRecording === false) {
        console.log("wake word recognized, startRecording");
        startRecording();
      }
    }
  },[startRecording, stopRecording, isRecording, isAllowed]);

  // Reusable interval clearing method for the recording auto end delay
  const clearRecordingDelayInterval = () => {
    if(delayIntervalID !== null) {
      //if it isn't null, fully remove it
      clearInterval(delayIntervalID);
      delayIntervalID = null;
    }
  }

  const clearStopRecordingTimeoutID = () => {

    console.log("clearStopRecordingTimeoutID");
    if(stopRecordingTimeoutID) {
      //if it isn't null, fully remove it
      clearInterval(stopRecordingTimeoutID);
      stopRecordingTimeoutID = null;
    }
  }

  const toggleRecording = () => {
    console.log("toggleRecording");
    if (audioState !== 'processing') {
      if (isTalking) {
        if (audioRef && audioRef.current) {
          audioRef.current.pause();
        }
      } else {
        if (isRecording) {
          // console.log("toggleRecording::stopRecording");
          stopRecording();
        } else {
          // console.log("toggleRecording::startRecording");
          startRecording();
        }
      }
    }
  };

  // const handleClick = (e) => {
  //   // e.preventDefault();
  //   console.log("handleClick");
  //   if(isRecording) stopRecording();//stopRecording();
  // };

  // useEffect(() => {
  //   document.addEventListener('click', handleClick);

  //   // Cleanup the event listener on unmount
  //   return () => {
  //     document.removeEventListener('click', handleClick);
  //   };
  // }, []);

  useEffect(() => {
    if (isAllowed) {
      console.log('ON', isListening);
      let micStream;
  
      function initMediaRecorder() {
        if (audioStreamRef?.current) {
          console.log('STREAM FOUND, RETURN');
          return;
        }
  
        if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
          console.error('Media Devices API or getUserMedia not supported in this browser.');
          return;
        }
  
        try {
          if (!audioStreamRef?.current) {
            console.log('NO STREAM', audioStreamRef);
            audioStreamRef.current = navigator.mediaDevices.getUserMedia({audio: true}).then((stream) => {
  
              setIsMediaRecorderLoaded(true);
              if(voiceActivated) initSpeechRecognition();
              
              let mimeType = "";
              let fileType = "";
  
              if (MediaRecorder.isTypeSupported('audio/webm;codecs=opus')) {
                  mimeType = "audio/webm;codecs=opus";
                  fileType = "webm";
              } else if (MediaRecorder.isTypeSupported('audio/ogg;codecs=opus')) {
                  mimeType = "audio/ogg;codecs=opus";
                  fileType = "ogg";
              } else if (MediaRecorder.isTypeSupported('audio/wav')) {
                  mimeType = "audio/wav";
                  fileType = "wav";
              } else if (MediaRecorder.isTypeSupported('audio/mp4')) {
                  mimeType = 'audio/mp4';
                  fileType = "mp4";
                  // mimeType = "audio/wav";
                  // fileType = "wav";
              } else {
                  //console.error('USE No supported audio codecs found');
              }
  
              if (!mediaRecorderRef?.current) {
                console.log('OBTAIN RECORDER', mediaRecorderRef);
                mediaRecorderRef.current = new MediaRecorder(stream);
  
                if (analyzer) {
                  micStream = analyzer.audioCtx.createMediaStreamSource(stream);
                  analyzer.connectInput(micStream);
                  analyzer.volume = 0;
                }
  
                console.log('INIT RECORDER');
  
                let recordedChunks = [];
  
                mediaRecorderRef.current.ondataavailable = (event) => {
                  if (event.data.size > 0) {
                    recordedChunks.push(event.data);
                  }
                };
  
                mediaRecorderRef.current.onstop = async () => {
                  // console.log("mediaRecorder::onstop");
                  onStateChange('processing');
                  const audioBlob = new Blob(recordedChunks, {type: mimeType});
  
                  recordedChunks = [];
                  try {
                    const transcriptRes = await getAudioTranscript(audioBlob, mimeType, fileType, module?.language);
  
                    console.log('Audio recording stopped and processed.', transcriptRes);
  
                    onStateChange('ready', transcriptRes);
  
                  } catch (e) {
                    console.log('ERROR RECORDER ONSTOP',e);
                    showBoundary(e);
                  }
                };
              }
              else {
                console.log('RECORDER EXISTS', mediaRecorderRef);
              }
  
            }).catch(e => {
              setIsAllowed(false);
            });
          }
          else {
            console.log('STREAM EXISTS', audioStreamRef);
          }
  
        } catch (e) {
          showBoundary(e);
        }
      }
      
      // function initSpeechRecognition() {
      //   onStateChange('listening');
      // }

      function initSpeechRecognition() {
        if (speechRecognitionRef?.current) {
          // Refresh the onend handler so isListening/Talking is updated within it
          speechRecognitionRef.current.onend = onSpeechRecognitionEnd;
          speechRecognitionRef.current.onresult = onSpeechRecognitionResult;
        }
  
        if (!speechRecognitionRef?.current && isListening) {
          if('speechrecognition' in window) {
            console.log("in streamMic speechrecognition in window");
            speechRecognitionRef.current = new window.speechrecognition();
          } else if('webkitSpeechRecognition' in window) {
            console.log("in streamMic webkitSpeechRecognition in window");
            speechRecognitionRef.current = new window.webkitSpeechRecognition();
          } else {
            console.log('SpeechRecognition web API not found');
          }
  
          if (speechRecognitionRef?.current) {
            const audiostart = () => {
                console.log("speech recognition - onaudiostart");
                //onStateChange('listening');
              },
              speechstart = () => {
                console.log("speech recognition - onspeechstart");
                // Remove delayed stop recording interval while speech is being recognized
                clearRecordingDelayInterval();
              },
              speechend = () => {
                console.log("speech recognition - onspeechend");
              },
              error = (e) => {
                console.log('speech recognition - ONERROR',e);
              };
  
            console.log('INIT speechRecognitionRef');
            speechRecognitionRef.current.lang = 'en-US';
            // These params didn't seem to help
            // speechRecognitionRef.current.continuous = true;
            // speechRecognitionRef.current.interimResults = true;
            onStateChange('listening');
            speechRecognitionRef.current.start();
            setSess('speechRecognitionRef', 'started');
  
            // Fires when speech recognition service has begun listening to incoming audio
            speechRecognitionRef.current.onaudiostart = audiostart;
            // Fires when human speech is recognized (person starts talking)
            speechRecognitionRef.current.onspeechstart = speechstart
            // Fires when it stops recognizing human speech (person stops talking)
            speechRecognitionRef.current.onspeechend = speechend;
            // Fires when the speech recognition service returns a result — a word or phrase has been positively recognized and this has been communicated back to the app. Also available via the onresult property.
            speechRecognitionRef.current.onresult = onSpeechRecognitionResult;
            speechRecognitionRef.current.onerror = error;
            speechRecognitionRef.current.onend = onSpeechRecognitionEnd;
          } else {
            console.log('SpeechRecognition web API not found');
          }
        }
      }
  
      console.log('INIT AUDIO CONTROL');
  
      if (isAllowed) {
        initMediaRecorder();
      
        if (isMediaRecorderLoaded) {
          // initSpeechRecognition();
        }
      }
  
      return () => {
        // This fires too often, and would needlessly stop listeners (with or without dependencies provided)
        console.log('OFF');
  //   if (speechRecognitionRef.current) {
        //     console.log('stopping speechRecognitionRef');
        //     speechRecognitionRef.current.stop();
        //     //speechRecognitionRef.current = null;
        //   }
        //   if (audioStreamRef.current && audioStreamRef.current.getAudioTracks) {
        //     const tracks = audioStreamRef.current.getAudioTracks();
        //     console.log('stopping audioStreamRef.current');
        //
        //     tracks[0].stop();
        //     //audioStreamRef.current = null;
        //   }
      };
    }
  }, [onStateChange, startRecording, stopRecording, speechRecognitionRef, onSpeechRecognitionEnd, onSpeechRecognitionResult, isAllowed, isListening, isRecording, analyzer, module, isMediaRecorderLoaded, showBoundary, voiceActivated]);

  useEffect(() => {
    if (isAllowed) {
      if (!document.hidden) {
        if (speechRecognitionRef.current && !isListening && !isTalking && audioState === 'listening') {
          console.log('STOPPED TALKING, START LISTENING',audioState,speechRecognitionRef.current)
          setIsListening(true);
          onStateChange('listening');
          if (getSess('speechRecognitionRef') === 'stopped') {
            speechRecognitionRef.current.start();
            setSess('speechRecognitionRef', 'started');
          }
        }
        if (isListening && isTalking) {
          console.log("isListening & isTalking is true, STOP LISTENING");
          stopListening();
        }
      }
    }
  }, [speechRecognitionRef, onStateChange, isListening, isTalking, audioState, stopListening, isAllowed]);

  return (
    <>
      {!module.isGuide && //only show buttons if not guide
        <div className={'flex-grow-1 col-12 my-2 py-2 audio-control '+ audioState}>
          <button disabled={audioState === 'processing'} className="talk" onClick={toggleRecording}></button>
          <button className="end" onClick={() => setIsEnding(true)}></button>
        </div>
      } 
      {module.isGuide && // display hidden control
        <div className={'audio-control-guide '+ audioState}>
          <button disabled={audioState === 'processing'} className="talk-guide" onClick={toggleRecording}></button>
        </div>
      }
      <Modal isOpen={isEnding}>
        <span>Are you ready to end this interaction{module?.eval ? ' and receive your evaluation' : ''}?</span>
        <div className="d-flex">
          <button onClick={() => setIsEnding(false)}>Cancel</button>
          <button onClick={() => window.location = '/' + tenant?.slug + (module?.eval ? '/' + module?.slug + '-evaluation' : '')}>End</button>
        </div>
      </Modal>
      <Modal isOpen={isReady && !isAllowed}>
        <span>You have denied microphone permissions to the application. Please click retry to enable mic permissions.<br/><br />For Chrome users, please enable permissions manually in your browser before attempting to retry.<br /><br />Please see your trainer if you need assistance.</span>
        <div className="d-flex">
          <button onClick={() => window.location.reload()}>Retry</button>
        </div>
      </Modal>
    </>
  );
};

export default AudioControl;
