import '../stylesheets/Guide.scss';
import React, {useCallback, useEffect, useRef, useState} from "react";
import {useAddHistory} from "../../utils/hooks/historyHooks";
import {getLLMResponse} from "../../utils/fetch/llmFetching";
import AudioControl from "../general/AudioControl";
import {getAudioResponse} from "../../utils/fetch/speechFetching";
import {emptyTranscribeResponse} from "../../utils/constants";
import { getLocal, setLocal, setSess, getSess } from '../../utils/dataFetching';
import {sessionCreate, sessionList} from "../../utils/session/session";
import { useErrorBoundary } from "react-error-boundary";

import hero from '../../assets/default/images/assistant-hero.png';

import AudioMotionAnalyzer from 'audiomotion-analyzer';


const Guide = ({assistant, audioRef, audioPlayed}) => {
    const { showBoundary } = useErrorBoundary();

    const [tenant, setTenant] = useState(getLocal('tenant'));
  
    const [audioState, setAudioState] = useState('ready');
    const [isTalking, setIsTalking] = useState(false);
    const {mutateAsync:newHistoryObj} = useAddHistory();
    const [showIntro, setShowIntro] = useState(true);
    const [showLoopWaveform, setShowLoopWaveform] = useState(false);
  
    const audioLoopRef = useRef(null);
    const loopAnalyzerRef = useRef(null);
    const playbackAnalyzerRef = useRef(null);
    //const recordingAnalyzerRef = useRef(null);
    const [loopAnalyzer, setLoopAnalyzer] = useState(null);
    const [playbackAnalyzer, setPlaybackAnalyzer] = useState(null);
    const [recordingAnalyzer, setRecordingAnalyzer] = useState(null);
    const [aiTranscript, setAITranscript] = useState([]);
    const [step, setStep] = useState(0);
  
    const [kbSessionId, setkbSessionId] = useState(getLocal('kb_' + assistant?.slug + '_session_id'));
    const kbModId = assistant?._id;
    
    const getSession = useCallback(async () => {
  
      try {
        if (!kbSessionId) {
          // Check if session exists in DB
          const sessionResList = await sessionList();
          sessionResList.data.sessions?.forEach(s => {
            if (s.module === kbModId) {
              setkbSessionId(s._id);
              setLocal('kb_' + assistant?.slug + '_session_id', s._id);
            }
          });
  
          // If empty, create one
          if (!kbSessionId) {
            const sessionRes = await sessionCreate(kbModId);
            if (sessionRes.data?.session) {
              setkbSessionId(sessionRes.data.session._id);
              setLocal('kb_'+ assistant?.slug + '_session_id', sessionRes.data.session._id);
            }
          }
        }
      } catch (e) {
        console.error('Error fetching session:', e);
        showBoundary(e);
      }
  
      console.log("kbSessionId");
      console.log(kbSessionId);
      setSess('guideStep', 0);
      document.title = "Xperiant AI Assistant";
    }, [assistant, kbModId, kbSessionId, showBoundary]);

    useEffect(() => {
      setStep(Number(getSess('guideStep')));
    }, [audioState]);
  
    useEffect(() => {
      const assetsUrl = process.env.REACT_APP_ASSETS_URL;
  
      document.documentElement.style.setProperty('--assistant-logo-make', `url('${assetsUrl}/${tenant?.slug}/${assistant?.slug}/logo-make.png')`);
      document.documentElement.style.setProperty('--assistant-vehicle', `url('${assetsUrl}/${tenant?.slug}/${assistant?.slug}/vehicle.png')`);
  
      getSession();
    }, [tenant, assistant, getSession]);
  
    const handlePlayAudio = useCallback(async (text) => {
      if (audioPlayed && audioRef.current) {

        console.log('handlePlayAudio');
        console.log(text);
        let json;

        try {
          const cleanedString = text.replace(/```json\n|\n```/g, "").replaceAll('*', "").replaceAll('\n', '');
          console.log(cleanedString);
          json = JSON.parse(cleanedString);
          console.log('json parsed');
          // setStep(prevStep => prevStep + 1);
          let gs = getSess('guideStep');
          setSess('guideStep', Number(gs) + 1);
        } catch (e) {
          json = null;
        }

        console.log('handlePlayAudio :: json')
        console.log(json);
        const toSend = json && json.instructions ? json : text;
        console.log('handlePlayAudio :: toSend');
        console.log(toSend);
        //fix for if instructions is an array instead of a string
        let contentString = toSend.instructions && typeof(toSend.instructions) !== 'string' ? toSend.instructions.join('. ') : toSend.instructions;
        console.log(contentString);
 
        setAITranscript(prev => [...prev, json ? json : text]);
        // setStep(prevStep => prevStep + 1);
        

        const response = await getAudioResponse(contentString ? contentString : toSend, kbSessionId, assistant?.avatarVoice || getLocal('kb_audio'));
  
        audioRef.current.src = response.data.filepath;
        audioRef.current.load();
        setShowLoopWaveform(false);
  
        audioRef.current.oncanplay = () => {
          audioRef.current.play().catch(e => {
            alert('Error on play');
            console.log(e);
            showBoundary(e);
          });
  
          setIsTalking(true);
          setAudioState('playing');
          
          if (audioLoopRef.current) {
            audioLoopRef.current.src = '../../assets/default/audio/listening-loop.mp3';
            audioLoopRef.current.load();
            
            audioLoopRef.current.oncanplay = () => {
              loopAnalyzer.volume = 0;
  
              audioLoopRef.current.play().catch(e => {
                console.log('ERROR: Audio loop playback failed.');
                showBoundary(e);
              });
            };
          }
        }
  
        audioRef.current.onpause = () => {
          setIsTalking(false);
          setAudioState('listening');
          setShowLoopWaveform(true);
        };
  
        await newHistoryObj({sender: 'AI', text: text});
          console.log('played audioRef');
      }
    }, [assistant, audioRef, audioPlayed, newHistoryObj, loopAnalyzer, kbSessionId, showBoundary]);
  
    const handleAudioStateChange = useCallback(async (state, transRes) => {
      if (audioPlayed) {
        if (state !== 'listening') {
          setShowIntro(false);
        }
    
        if (state === 'recording') {
          if (audioRef.current) {
            audioRef.current.src = '../../assets/default/audio/listening.mp3';
            audioRef.current.load();
            setShowLoopWaveform(false);
    
            audioRef.current.oncanplay = () => {
              setShowLoopWaveform(false);
  
              audioRef.current.play().catch(e => {
                console.log('ERROR: Audio cue playback failed.');
                showBoundary(e);
              });
            };
    
            audioRef.current.onpause = () => {
              setShowLoopWaveform(true);
              /*
              if (audioLoopRef.current) {
                audioLoopRef.current.src = '../../assets/default/audio/listening-loop.mp3';
                audioLoopRef.current.load();
                console.log('AUDIO LOOP LISTENING 2');
                audioLoopRef.current.oncanplay = () => {
                  audioLoopRef.current.play().catch(e => {
                    console.log('ERROR: Audio loop playback failed.');
                  });
                };
              }
              */
            };
          }
        } else if (state === 'processing') {
          if (audioRef.current) {
            audioRef.current.src = '../../assets/default/audio/processing.mp3';
            audioRef.current.load();
            setShowLoopWaveform(false);
    
            audioRef.current.oncanplay = () => {
              setShowLoopWaveform(false);
  
              audioRef.current.play().catch(e => {
                console.log('ERROR: Audio cue playback failed.');
                showBoundary(e);
              });
            };
  
            audioRef.current.onpause = () => {
              setShowLoopWaveform(true);
            };
          }
          
          if (audioLoopRef.current) {
            audioLoopRef.current.src = '../../assets/default/audio/processing-loop.mp3';
            audioLoopRef.current.load();
            setShowLoopWaveform(true);
            
            audioLoopRef.current.oncanplay = () => {
              loopAnalyzer.volume = 1;
  
              audioLoopRef.current.play().catch(e => {
                console.log('ERROR: Audio loop playback failed.');
                showBoundary(e);
              });
            };
          }
        }
    
        if (state === 'ready') {
          if (transRes && transRes.transcription) {
            await newHistoryObj({sender: 'Human', text: transRes.transcription});
            let gs = getSess('guideStep');
            if(!gs) gs = 0;
            
            const llmRes = await getLLMResponse(kbSessionId, kbModId, `{{step: ${gs}}} ${transRes.transcription}`, "", "", "", "", "", true, {type:'count', value:0});
            const regex = /Step (\d+):/g;
    
              if (llmRes.data?.content) {
                let c = llmRes.data.content.replace(regex, "");
              await handlePlayAudio(c);
              }
          } else {
            await handlePlayAudio(emptyTranscribeResponse);
          }
        } else {
          setIsTalking(false);
          setAudioState(state);
        }
      }
    }, [audioRef, audioPlayed, newHistoryObj, handlePlayAudio, kbModId, kbSessionId, loopAnalyzer, showBoundary]);
  
    // useEffect(() => {
    //   const analyzerOptions = {
    //     barSpace: 0.25,
    //     bgAlpha: 0,
    //     gradient: 'knowledgeAssistant',
    //     mode: 2,
    //     overlay: true,
    //     reflexAlpha: 1,
    //     reflexBright: 1,
    //     reflexRatio: 0.5,
    //     roundBars: true,
    //     showPeaks: false,
    //     showScaleX: false,
    //     smoothing: 0.7
    //   };
    //   const gradientOptions = {
    //     colorStops: [
    //       '#C0B3A5',
    //       '#0F0D0B'
    //     ]
    //   };
  
    //   let _loopAnalyzer = new AudioMotionAnalyzer(loopAnalyzerRef.current);
    //   _loopAnalyzer.registerGradient('knowledgeAssistant', gradientOptions);
    //   _loopAnalyzer.setOptions({...analyzerOptions, linearAmplitude: true, linearBoost: 1.2, volume: 0});
    //   _loopAnalyzer.connectInput(audioLoopRef.current);
    //   setLoopAnalyzer(_loopAnalyzer);
  
    //   let _playbackAnalyzer = new AudioMotionAnalyzer(playbackAnalyzerRef.current);
    //   _playbackAnalyzer.registerGradient('knowledgeAssistant', gradientOptions);
    //   _playbackAnalyzer.setOptions({...analyzerOptions, minFreq: 20, maxFreq: 7000 });
    //   _playbackAnalyzer.connectInput(audioRef.current);
    //   setPlaybackAnalyzer(_playbackAnalyzer);
    //   /*
    //   let _recordingAnalyzer = new AudioMotionAnalyzer(recordingAnalyzerRef.current);
    //   _recordingAnalyzer.registerGradient('knowledgeAssistant', gradientOptions);
    //   _recordingAnalyzer.setOptions(analyzerOptions);
    //   setRecordingAnalyzer(_recordingAnalyzer);
    //   */
    // }, [audioRef, setPlaybackAnalyzer]);
  
    // useEffect(() => {
    //   if (audioPlayed && audioLoopRef.current) {
    //     audioLoopRef.current.src = '../../assets/default/audio/listening-loop.mp3';
    //     audioLoopRef.current.load();
    //     setShowLoopWaveform(true);
        
    //     audioLoopRef.current.oncanplay = () => {
    //       loopAnalyzer.volume = 0;
  
    //       audioLoopRef.current.play().catch(e => {
    //         console.log('ERROR: Audio loop playback failed.');
    //         showBoundary(e);
    //       });
    //     };
    //   }
    // }, [audioPlayed, loopAnalyzer, showBoundary]);
  
    return (<div className={"guide-container d-flex flex-column justify-content-center align-items-center"}>
        {audioPlayed ? (
          <AudioControl isTalking={isTalking} audioState={audioState} onStateChange={handleAudioStateChange} analyzer={recordingAnalyzer} audioRef={audioRef} module={assistant} voiceActivated={true} />
         ) : null}
         <div className="title-bar">Cathode Change Procedure</div>
        {/* <a className="app-link not-mobile" href={'/' + tenant?.slug + '/menu'}>&lt; menu</a>
        <a className="app-link mobile" href={'/' + tenant?.slug + '/menu'}>&lt; menu</a> */}
        <div className="kb-upper">
          <div className="logo-container">
            {/* <div className="model">{assistant?.title}</div> */}
          </div>
        </div>
        <div className="vehicle-container">
          <img className="vehicle" src={hero} alt="vehicle"/>
        </div>

        

        {step === 2 ? <div className="tools" /> : ''}
        {/* <img src={'../stylesheets/tools_needed.png'} /> */}


        <div className="transcript">
            <div className="content">
                {aiTranscript.map((t, i) => (
                      typeof(t) !== 'string' ?
                      // t is not string; assume json
                      <div key={i}>
                        <p>{t.step ? `Step: ${t.step}` : ''}</p>
                        {typeof(t.instructions) !== 'string' ? t.instructions.map((ins, idx) => (
                          <p key={idx}>{ins}</p> 
                        )) : <p>{t.instructions}</p>}
                        
                        {t.images.map((img, idx) => (
                          <img key={idx} src={img} alt=""/>
                        ))} 
                      </div>
                      // t is just a string
                      : <p key={i}>{t}</p>

                    ))}
            </div>
        </div>
        
        {/* <div className="kb-lower">
          <div className={'waveform' + (showIntro || !showLoopWaveform ? ' hidden' : '')} ref={loopAnalyzerRef}></div>
          <div className={'waveform' + (showIntro || showLoopWaveform ? ' hidden' : '')} ref={playbackAnalyzerRef}></div> */}
          {/* <div className={'waveform' + (showIntro || audioState === 'playing' || audioState === 'processing' ? ' hidden' : '')} ref={recordingAnalyzerRef}></div> */}
          {/* <audio loop={true} src="../../assets/default/audio/listening-loop.mp3" ref={audioLoopRef} />
        </div> */}
      </div>
    );
}

export default Guide; 